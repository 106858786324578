import Immutable from "immutable";
import Ajv from "ajv";

import ajax from "./ajax";

/**
 * This selector creates a usable `Ajv` instance of the swagger spec.
 * This will only be parsed once, as the data will not have changed;
 * this ensures that the logic is not re-applied on every prop change
 */

const ajv = new Ajv({ removeAdditional: true, allErrors: true, verbose: true });
ajv.addFormat("password", /.*/);

if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line no-console
  console.log("[ajv] compiling openapi.json");
}

const schema = Immutable.fromJS({});
ajv.compile(schema.set("$id", "api").toJS());

export { ajv, schema };

export default ajax;
