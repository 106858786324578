import Immutable from "seamless-immutable";

import api from "../../inc/api";

// ------------------------------------
// Actions
// ------------------------------------

export const HYDRATE_CARDS = "@products/HYDRATE_CARDS";
export const HYDRATE_SHIPPING_METHODS = "@products/HYDRATE_SHIPPING_METHODS";
export const HYDRATE_PAYMENT_METHODS = "@products/HYDRATE_PAYMENT_METHODS";
export const HYDRATE_WEB_PRODUCTS = "@products/HYDRATE_WEB_PRODUCTS";
export const HYDRATE_PRODUCTS = "@products/HYDRATE_PRODUCTS";
export const HYDRATE_TIME_FRAMES = "@products/HYDRATE_TIME_FRAMES";

// ------------------------------------
// Action generators
// ------------------------------------

export const hydrateCards = q => ({
  type: HYDRATE_CARDS,
  payload: api.get("/workbench/card", { q })
});

export const hydrateTimeFrames = q => ({
  type: HYDRATE_TIME_FRAMES,
  payload: api.get("/workbench/timeFrame", { q })
});

export const hydrateShippingMethods = q => ({
  type: HYDRATE_SHIPPING_METHODS,
  payload: api.get("/workbench/shippingMethod", { q })
});

export const hydratePaymentMethods = q => ({
  type: HYDRATE_PAYMENT_METHODS,
  payload: api.get("/workbench/paymentMethod", { q })
});

// hmz...
export const hydrateWebProducts = (q, useCurrent = false) => (dispatch, getState) => {
  const state = getState();
  if (useCurrent && state.products?.webProducts?.data) {
    return dispatch({
      type: HYDRATE_WEB_PRODUCTS,
      payload: Promise.resolve(state.products?.webProducts?.data)
    });
  }
  return dispatch({
    type: HYDRATE_WEB_PRODUCTS,
    payload: api.get("/workbench/webProduct", { q })
  });
};

export const hydrateProducts = q => ({
  type: HYDRATE_PRODUCTS,
  payload: api.get("/workbench/product", { q })
});

// ------------------------------------
// Default State
// ------------------------------------

const initialState = Immutable.from({
  cards: {},
  timeFrames: {},
  shippingMethods: {},
  paymentMethods: {},
  webProducts: {},
  products: {}
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const hydrateHandler = prefix => {
  return (state, { status, payload }) => {
    // console.log("status", prefix, status, state);
    switch (status) {
      case "pending":
        return state.setIn(`${prefix}.isHydrating`.split("."), true);
      case "success":
        return state
          .setIn(`${prefix}.isHydrating`.split("."), false)
          .setIn(`${prefix}.hasHydrated`.split("."), true)
          .setIn(`${prefix}.data`.split("."), Immutable.from(payload));
      case "error":
        return state
          .setIn(`${prefix}.isHydrating`.split("."), false)
          .setIn(`${prefix}.hydrateError`.split("."), payload);
      default:
        return state;
    }
  };
};

const ACTION_HANDLERS = {
  [HYDRATE_CARDS]: hydrateHandler("cards"),
  [HYDRATE_TIME_FRAMES]: hydrateHandler("timeFrames"),
  [HYDRATE_SHIPPING_METHODS]: hydrateHandler("shippingMethods"),
  [HYDRATE_PAYMENT_METHODS]: hydrateHandler("paymentMethods"),
  [HYDRATE_WEB_PRODUCTS]: hydrateHandler("webProducts"),
  [HYDRATE_PRODUCTS]: hydrateHandler("products")
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
