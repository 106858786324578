import Immutable from "immutable";
import api from "../../inc/api";
import { getParams } from "../../inc/RouteUtil";

// ------------------------------------
// Actions
// ------------------------------------

export const PRINT = "@app/order/PRINT";
export const HYDRATE = "@app/order/HYDRATE";
export const HYDRATE_ONE = "@app/order/HYDRATE_ONE";
export const HYDRATE_INTERNAL_LOG = "@app/order/HYDRATE_INTERNAL_LOG";
export const DELETE_TAG = "@app/order/DELETE_TAG";

export const SEND_TO_EF = "@app/order/SEND_TO_EF";

export const SEND_TO_DYNA = "@app/order/SEND_TO_DYNA";
export const HYDRATE_DYNA_CONFIG = "@app/order/HYDRATE_DYNA_CONFIG";

export const SEND_TO_RJP = "@app/order/SEND_TO_RJP";
export const HYDRATE_RJP_CONFIG = "@app/order/HYDRATE_RJP_CONFIG";

export const SEND_TO_PACKS = "@app/order/SEND_TO_PACKS";
export const HYDRATE_PACKS_CONFIG = "@app/order/HYDRATE_PACKS_CONFIG";

export const SEND_TO_EUROFLORIST = "@app/order/SEND_TO_EUROFLORIST";
export const MARK_AS_EUROFLORIST = "@app/order/MARK_AS_EUROFLORIST";

export const SEND_TO_TOPBLOEMEN = "@app/order/SEND_TO_TOPBLOEMEN";
export const MARK_AS_TOPBLOEMEN = "@app/order/MARK_AS_TOPBLOEMEN";

export const SEND_CONFIRMATION = "@app/order/SEND_CONFIRMATION";
export const SEND_INVOICE = "@app/order/SEND_INVOICE";

export const CLEAR_SEARCH = "@app/order/CLEAR_SEARCH";

// ------------------------------------
// Action generators
// ------------------------------------
export const UPDATE_ACTIVE_ORDER_OPEN = "@app/order/ACTIVE_ORDER_OPEN";
export const updateActiveOrderOpen = (id, userIds) => ({
  type: UPDATE_ACTIVE_ORDER_OPEN,
  payload: {
    id,
    userIds
  }
});

export const RELEASE_BARCODE = "@app/order/RELEASE_BARCODE";
export const releaseBarcode = barcode => ({
  type: RELEASE_BARCODE,
  payload: api.post("/workbench/barcode/release", { barcode })
});

export const hydrateInternalLog = orderId => ({
  type: HYDRATE_INTERNAL_LOG,
  payload: api.get(`/workbench/order/${orderId}/internalLog`)
});

export const DELETE_INTERNAL_LOG_ENTRY = "@app/order/DELETE_INTERNAL_LOG_ENTRY";
export const deleteInternalLogEntry = (orderId, internalLogEntryId) => ({
  type: DELETE_INTERNAL_LOG_ENTRY,
  internalLogEntryId,
  payload: api.del(`/workbench/order/${orderId}/internalLog/${internalLogEntryId}`)
});

export const UNDELETE_ORDER = "@app/order/UNDELETE";
export const undelete = orderId => ({
  type: UNDELETE_ORDER,
  payload: api.post(`/workbench/order/${orderId}/undelete`)
});

export const clearSearch = () => ({
  type: CLEAR_SEARCH,
  payload: {}
});

export const fetchRedjepakketjeOptions = (orderId, data) => ({
  type: HYDRATE_RJP_CONFIG,
  payload: api.get(`/workbench/order/${orderId}/transport/redjepakketje/config`, data)
});

export const sendToRedjepakketje = (orderId, data) => ({
  type: SEND_TO_RJP,
  payload: api.post(`/workbench/order/${orderId}/transport/redjepakketje/register`, data)
});

export const fetchDynalogicOptions = (orderId, data) => ({
  type: HYDRATE_DYNA_CONFIG,
  payload: api.get(`/workbench/order/${orderId}/transport/dynalogic/config`, data)
});

export const sendToDynalogic = (orderId, data) => ({
  type: SEND_TO_DYNA,
  payload: api.post(`/workbench/order/${orderId}/transport/dynalogic/register`, data)
});

export const fetchPacksOptions = (orderId, data) => ({
  type: HYDRATE_PACKS_CONFIG,
  payload: api.get(`/workbench/order/${orderId}/transport/packs/config`, data)
});

export const sendToPacks = (id, data) => ({
  type: SEND_TO_PACKS,
  payload: api.post(`/workbench/order/${id}/transport/packs/register`, data || { id })
});

export const sendToEuroFlorist = id => ({
  type: SEND_TO_EUROFLORIST,
  payload: api.post(`/workbench/order/${id}/transport/euroflorist/register`, { id })
});

export const markAsEuroFlorist = (id, data) => ({
  type: MARK_AS_EUROFLORIST,
  payload: api.post(`/workbench/order/${id}/transport/euroflorist/mark`, data || { id })
});

export const sendToEuroFloristEmail = (id, data) => ({
  type: SEND_TO_EF,
  payload: api.post(`/workbench/order/${id}/transport/euroflorist/email`, data)
});

export const sendToTopBloemen = id => ({
  type: SEND_TO_TOPBLOEMEN,
  payload: api.post(`/workbench/order/${id}/transport/topbloemen/register`, { id })
});

export const SEND_TO_HAPPY = "@app/order/SEND_TO_HAPPY";
export const HYDRATE_HAPPY_CONFIG = "@app/order/HYDRATE_HAPPY_CONFIG";
export const sendToHappy = (orderId, data) => ({
  type: SEND_TO_HAPPY,
  payload: api.post(`/workbench/order/${orderId}/transport/happy/register`, data)
});
export const fetchHappyOptions = (orderId, data) => ({
  type: HYDRATE_HAPPY_CONFIG,
  payload: api.get(`/workbench/order/${orderId}/transport/happy/config`, data)
});

export const markAsTopBloemen = (id, data) => ({
  type: MARK_AS_TOPBLOEMEN,
  payload: api.post(`/workbench/order/${id}/transport/topbloemen/mark`, data || { id })
});

export const print = (orderId, jobType, printer) => ({
  type: PRINT,
  jobType,
  payload: api.post(`/workbench/order/${orderId}/print`, { jobType, printer })
});

export const hydrate = (filters, quiet) => ({
  type: HYDRATE,
  quiet,
  payload: api.get("/workbench/order", filters)
});

export const hydrateOne = (orderId, quiet) => ({
  type: HYDRATE_ONE,
  quiet,
  payload: api.get(`/workbench/order/${orderId}`)
});

export const removeOrderFromTag = (orderId, tagId) => ({
  type: DELETE_TAG,
  payload: api.del(`/workbench/order/${orderId}/tag/${tagId}`)
});

export const SET_EDITING = "@app/order/SET_EDITING";
export const setEditing = (key, value) => ({
  type: SET_EDITING,
  payload: { key, value }
});

export const DATA_UPDATE = "@app/order/UPDATE";
export const updateOrder = data => ({
  type: DATA_UPDATE,
  payload: api.put(`/workbench/order/${data.id}`, data)
});

export const sendConfirmation = (id, type) => ({
  type: SEND_CONFIRMATION,
  payload: api.post(`/workbench/order/${id}/sendConfirmation`, { id, type })
});

export const sendInvoice = id => ({
  type: SEND_INVOICE,
  payload: api.post(`/workbench/order/${id}/sendConfirmation`, { id, type: "invoice" })
});

export const SET_ACTIVE_TAB = "@app/order/SET_ACTIVE_TAB";
export const setActiveTab = idx => ({ type: SET_ACTIVE_TAB, payload: idx });

export const TAG_CREATE = "@app/order/TAG_CREATE";
export const addTag = tag => ({
  type: TAG_CREATE,
  payload: api.post("/workbench/tag", { tag })
});

export const TAG_ORDER = "@app/order/TAG_ORDER";
export const tagOrder = (id, tagId) => ({
  type: TAG_ORDER,
  payload: api.post(`/workbench/order/${id}/tag/${tagId}`)
});

export const SET_ACTIVE_ORDER_SCROLL_TOP = "@app/order/SET_ACTIVE_ORDER_SCROLL_TOP";
export const setActiveOrderScrollTop = yPos => ({
  type: SET_ACTIVE_ORDER_SCROLL_TOP,
  payload: yPos
});

export const ADD_TO_WORKLIST = "@app/order/ADD_TO_WORKLIST";
export const addToWorklist = orderId => ({
  type: ADD_TO_WORKLIST,
  payload: api.post(`/workbench/order/${orderId}/userTag`)
});

export const UPDATE_PROCESSING = "@app/order/UPDATE_PROCESSING";
export const updateOrderProcessing = (orderId, data) => ({
  type: UPDATE_PROCESSING,
  payload: api.put(`/workbench/order/${orderId}/processStatus`, data)
});

export const UPDATE_ORDER_ITEM_PROCESSING = "@app/order/UPDATE_ORDER_ITEM_PROCESSING";
export const updateOrderItemProcessing = (orderId, orderItemId, value) => ({
  type: UPDATE_ORDER_ITEM_PROCESSING,
  payload: api.put(`/workbench/order/${orderId}/processStatus`, { [orderItemId]: value })
});

export const FIND_BY_BARCODE = "@app/order/FIND_BY_BARCODE";
export const findOrderByBarcode = filter => ({
  type: FIND_BY_BARCODE,
  payload: api.get("/workbench/order/byBarcode", filter)
});

export const ACTIVE_ORDER_TOGGLE_MAP = "@app/order/active/TOGGLE_MAP";
export const toggleMap = () => ({
  type: ACTIVE_ORDER_TOGGLE_MAP
});

// ------------------------------------
// Default State
// ------------------------------------

const State = Immutable.Record({
  // list view
  hasFetched: false,
  isFetching: false,
  isFetchingQuiet: false,
  location: "",
  filter: Immutable.Map({
    tags: Immutable.fromJS(["unprocessed"])
  }),
  data: Immutable.Map(),
  fetchError: null,
  isReleasingBarcode: false,
  // edit / process view
  active: Immutable.Map({
    openUserIds: Immutable.Map(),
    isFetching: false,
    hasFetched: false,
    data: Immutable.Map(),
    scrollTop: 0,
    editing: Immutable.Map(),
    isUpdating: false,
    updateError: null,
    printing: Immutable.Map(),
    showMap: false,
    isAddingToWorklist: false,
    isFetchingInternalLog: false,
    hasFetchedInternalLog: false,
    internalLogError: null,
    internalLog: Immutable.List(),
    euroflorist: Immutable.Map({
      isSubmitting: false,
      fetchError: null,
      data: Immutable.Map(),
      isMarking: false,
      markError: null
    }),
    topbloemen: Immutable.Map({
      isSubmitting: false,
      fetchError: null,
      data: Immutable.Map(),
      isMarking: false,
      markError: null
    }),
    dynalogic: Immutable.Map({
      isFetching: false,
      hasFetched: false,
      config: Immutable.Map(),
      fetchError: null,
      isSubmitting: false,
      data: Immutable.Map(),
      submitError: Immutable.Map()
    }),
    redjepakketje: Immutable.Map({
      isFetching: false,
      hasFetched: false,
      config: Immutable.Map(),
      fetchError: null,
      isSubmitting: false,
      data: Immutable.Map(),
      submitError: Immutable.Map()
    }),
    packs: Immutable.Map({
      isFetching: false,
      hasFetched: false,
      config: Immutable.Map(),
      fetchError: null,
      isSubmitting: false,
      data: Immutable.Map(),
      submitError: Immutable.Map()
    }),
    happy: Immutable.Map({
      isFetching: false,
      hasFetched: false,
      config: Immutable.Map(),
      fetchError: null,
      isSubmitting: false,
      data: Immutable.Map(),
      submitError: Immutable.Map()
    }),
    sendConfirmation: Immutable.Map({
      isFetching: false,
      hasFetched: false,
      config: Immutable.Map(),
      fetchError: null,
      isSubmitting: false,
      data: Immutable.Map(),
      submitError: Immutable.Map()
    }),
    sendInvoice: Immutable.Map({
      isFetching: false,
      hasFetched: false,
      config: Immutable.Map(),
      fetchError: null,
      isSubmitting: false,
      data: Immutable.Map(),
      submitError: Immutable.Map()
    })
  })
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [UPDATE_ORDER_ITEM_PROCESSING]: (state, { status, payload }) => {
    switch (status) {
      case "success": {
        const path = ["active", "data", "workbenchMeta", "processStatus"];
        return state.setIn(path, state.getIn(path).mergeDeep(Immutable.fromJS(payload)));
      }
      default:
        return state;
    }
  },
  [TAG_ORDER]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "isTagging"], true);
      case "success":
        return state.setIn(["active", "isTagging"], false).setIn(["active", "data", "tags"], Immutable.fromJS(payload));
      case "error":
        return state.setIn(["active", "isTagging"], false).setIn(["active", "tagError"], payload);
      default:
        return state;
    }
  },
  [RELEASE_BARCODE]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("isReleasingBarcode", true);
      case "success":
        return state.set("isReleasingBarcode", false);
      case "error":
        return state.set("isReleasingBarcode", false).set("releaseBarcodeError", payload);
      default:
        return state;
    }
  },
  [HYDRATE_INTERNAL_LOG]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "isFetchingInternalLog"], true);
      case "success":
        return state
          .setIn(["active", "isFetchingInternalLog"], false)
          .setIn(["active", "hasFetchedInternalLog"], true)
          .setIn(["active", "internalLog"], Immutable.fromJS(payload));
      case "error":
        return state.setIn(["active", "isFetchingInternalLog"], false).setIn(["active", "internalLogError"], payload);
      default:
        return state;
    }
  },
  [DELETE_INTERNAL_LOG_ENTRY]: (state, { status, internalLogEntryId, payload }) => {
    const entryIndex = state
      .getIn(["active", "data", "internalLog"])
      .findIndex(e => e.get("id") === internalLogEntryId);
    switch (status) {
      case "pending":
        return state.setIn(["active", "data", "internalLog", entryIndex, "isDeleting"], true);
      case "success":
        return state
          .setIn(["active", "data", "internalLog", entryIndex, "isDeleting"], false)
          .setIn(["active", "data", "internalLog", entryIndex, "deleteError"], null);
      case "error":
        return state
          .setIn(["active", "data", "internalLog", entryIndex, "isDeleting"], false)
          .setIn(["active", "data", "internalLog", entryIndex, "deleteError"], payload);
      default:
        return state;
    }
  },
  [ACTIVE_ORDER_TOGGLE_MAP]: state => state.setIn(["active", "showMap"], !state.getIn(["active", "showMap"])),
  "@@router/LOCATION_CHANGE": (state, { payload }) =>
    state.set("filter", Immutable.fromJS(getParams(payload.location.pathname))),
  [ADD_TO_WORKLIST]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "isAddingToWorklist"], true);
      case "success":
        return state.setIn(["active", "isAddingToWorklist"], false);
      case "error":
        return state.setIn(["active", "isAddingToWorklist"], false).setIn(["active", "addToWorkListError"], payload);
      default:
        return state;
    }
  },
  [UPDATE_ACTIVE_ORDER_OPEN]: (state, { payload }) =>
    state.setIn(["active", "openUserIds"], Immutable.fromJS(payload.userIds)),
  [DATA_UPDATE]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "isUpdating"], true);
      case "success":
        return state.setIn(["active", "isUpdating"], false).setIn(["active", "data"], Immutable.fromJS(payload));
      case "error":
        return state.setIn(["active", "isUpdating"], false).setIn(["active", "updateError"]);
      default:
        return state;
    }
  },
  [PRINT]: (state, { status, jobType, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "printing", jobType, "isPrinting"], true);
      case "success":
        return state
          .setIn(["active", "printing", jobType, "isPrinting"], false)
          .setIn(["active", "printing", jobType, "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "printing", jobType, "isPrinting"], false)
          .setIn(["active", "printing", jobType, "error"], payload);
      default:
        return state;
    }
  },
  [SEND_TO_EUROFLORIST]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "euroflorist", "isSubmitting"], true);
      case "success":
        return state
          .setIn(["active", "euroflorist", "isSubmitting"], false)
          .setIn(["active", "euroflorist", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "euroflorist", "isSubmitting"], false)
          .setIn(["active", "euroflorist", "fetchError"], payload);
      default:
        return state;
    }
  },
  [MARK_AS_EUROFLORIST]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "euroflorist", "isMarking"], true);
      case "success":
        return state
          .setIn(["active", "euroflorist", "isMarking"], false)
          .setIn(["active", "euroflorist", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "euroflorist", "isMarking"], false)
          .setIn(["active", "euroflorist", "markError"], payload);
      default:
        return state;
    }
  },
  [SEND_TO_TOPBLOEMEN]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "topbloemen", "isSubmitting"], true);
      case "success":
        return state
          .setIn(["active", "topbloemen", "isSubmitting"], false)
          .setIn(["active", "topbloemen", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "topbloemen", "isSubmitting"], false)
          .setIn(["active", "topbloemen", "submitError"], payload);
      default:
        return state;
    }
  },
  [MARK_AS_TOPBLOEMEN]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "topbloemen", "isMarking"], true);
      case "success":
        return state
          .setIn(["active", "topbloemen", "isMarking"], false)
          .setIn(["active", "topbloemen", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "topbloemen", "isMarking"], false)
          .setIn(["active", "topbloemen", "markError"], payload);
      default:
        return state;
    }
  },
  [HYDRATE_DYNA_CONFIG]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "dynalogic", "isFetching"], true);
      case "success":
        return state
          .setIn(["active", "dynalogic", "isFetching"], false)
          .setIn(["active", "dynalogic", "hasFetched"], true)
          .setIn(["active", "dynalogic", "config"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "dynalogic", "isFetching"], false)
          .setIn(["active", "dynalogic", "fetchError"], payload);
      default:
        return state;
    }
  },
  [SEND_TO_DYNA]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "dynalogic", "isSubmitting"], true);
      case "success":
        return state
          .setIn(["active", "dynalogic", "isSubmitting"], false)
          .setIn(["active", "dynalogic", "config"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "dynalogic", "isSubmitting"], false)
          .setIn(["active", "dynalogic", "submitError"], payload);
      default:
        return state;
    }
  },
  [HYDRATE_RJP_CONFIG]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "redjepakketje", "isFetching"], true);
      case "success":
        return state
          .setIn(["active", "redjepakketje", "isFetching"], false)
          .setIn(["active", "redjepakketje", "hasFetched"], true)
          .setIn(["active", "redjepakketje", "config"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "redjepakketje", "isFetching"], false)
          .setIn(["active", "redjepakketje", "fetchError"], payload);
      default:
        return state;
    }
  },
  [SEND_TO_RJP]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "redjepakketje", "isSubmitting"], true);
      case "success":
        return state
          .setIn(["active", "redjepakketje", "isSubmitting"], false)
          .setIn(["active", "redjepakketje", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "redjepakketje", "isSubmitting"], false)
          .setIn(["active", "redjepakketje", "submitError"], payload);
      default:
        return state;
    }
  },
  [HYDRATE_HAPPY_CONFIG]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "happy", "isFetching"], true);
      case "success":
        return state
          .setIn(["active", "happy", "isFetching"], false)
          .setIn(["active", "happy", "hasFetched"], true)
          .setIn(["active", "happy", "config"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "happy", "isFetching"], false)
          .setIn(["active", "happy", "fetchError"], payload);
      default:
        return state;
    }
  },
  [SEND_TO_HAPPY]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "happy", "isSubmitting"], true);
      case "success":
        return state
          .setIn(["active", "happy", "isSubmitting"], false)
          .setIn(["active", "happy", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "happy", "isSubmitting"], false)
          .setIn(["active", "happy", "submitError"], payload);
      default:
        return state;
    }
  },
  [HYDRATE_PACKS_CONFIG]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "packs", "isFetching"], true);
      case "success":
        return state
          .setIn(["active", "packs", "isFetching"], false)
          .setIn(["active", "packs", "hasFetched"], true)
          .setIn(["active", "packs", "config"], Immutable.fromJS(payload));
      case "error":
        return state.setIn(["active", "packs", "isFetching"], false).setIn(["active", "packs", "fetchError"], payload);
      default:
        return state;
    }
  },
  [SEND_TO_PACKS]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "packs", "isSubmitting"], true);
      case "success":
        return state
          .setIn(["active", "packs", "isSubmitting"], false)
          .setIn(["active", "packs", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "packs", "isSubmitting"], false)
          .setIn(["active", "packs", "submitError"], payload);
      default:
        return state;
    }
  },
  [SEND_CONFIRMATION]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "sendConfirmation", "isSubmitting"], true);
      case "success":
        return state
          .setIn(["active", "sendConfirmation", "isSubmitting"], false)
          .setIn(["active", "sendConfirmation", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "sendConfirmation", "isSubmitting"], false)
          .setIn(["active", "sendConfirmation", "error"], payload);
      default:
        return state;
    }
  },
  [SEND_INVOICE]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "sendInvoice", "isSubmitting"], true);
      case "success":
        return state
          .setIn(["active", "sendInvoice", "isSubmitting"], false)
          .setIn(["active", "sendInvoice", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["active", "sendInvoice", "isSubmitting"], false)
          .setIn(["active", "sendInvoice", "error"], payload);
      default:
        return state;
    }
  },
  [SET_EDITING]: (state, { payload }) => state.setIn(["active", "editing", payload.key], payload.value),
  [SET_ACTIVE_ORDER_SCROLL_TOP]: (state, { payload }) => state.setIn(["active", "scrollTop"], payload),
  [HYDRATE]: (state, { status, quiet, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["isFetching"], !quiet).setIn(["isFetchingQuiet"], quiet);
      case "success":
        return state
          .setIn(["isFetching"], false)
          .setIn(["isFetchingQuiet"], false)
          .setIn(["hasFetched"], true)
          .setIn(["data"], Immutable.fromJS(payload));
      case "error":
        return state
          .setIn(["isFetching"], false)
          .setIn(["isFetchingQuiet"], false)
          .setIn(["fetchError"], payload);
      default:
        return state;
    }
  },
  [HYDRATE_ONE]: (state, { status, quiet, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["active", "showMap"], false).setIn(["active", "isFetching"], !quiet);
      case "success":
        return state
          .setIn(["active", "isFetching"], false)
          .setIn(["active", "hasFetched"], true)
          .setIn(["active", "data"], Immutable.fromJS(payload))
          .setIn(["active", "scrollTop"], 0)
          .setIn(["active", "editing"], Immutable.Map())
          .setIn(["active", "fetchError"], null);
      case "error":
        return state.setIn(["active", "isFetching"], false).setIn(["active", "fetchError"], payload);
      default:
        return state;
    }
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
