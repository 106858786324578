import Immutable from "immutable";
import api from "../../inc/api";

// ------------------------------------
// Actions
// ------------------------------------

export const HYDRATE = "@app/tag/HYDRATE";
export const HYDRATE_QUIET = "@app/tag/HYDRATE_QUIET";
export const HYDRATE_PROCESSED = "@app/tag/HYDRATE_PROCESSED";
export const HYDRATE_DELIVERY_EXPIRED = "@app/tag/HYDRATE_DELIVERY_EXPIRED";

export const CREATE = "@app/tag/CREATE";
export const UPDATE = "@app/tag/UPDATE";
export const DESTROY = "@app/tag/DESTROY";

export const SET_LAST_TAG_LOCATION = "@app/tag/SET_LAST_TAG_LOCATION";

// ------------------------------------
// Action generators
// ------------------------------------
export const setLastTagLocation = pathname => {
  if (window.location.pathname.length > 1) {
    const prefix = window.location.pathname.split("/")[1];
    window.sessionStorage.setItem(`${prefix}.lastTagLocation`, pathname);
  }
  window.sessionStorage.setItem("lastTagLocation", pathname);
  return {
    type: SET_LAST_TAG_LOCATION,
    payload: pathname
  };
};

export const create = filters => ({
  type: CREATE,
  payload: api.post("/workbench/tag", filters)
});

export const update = (tagId, data) => ({
  type: UPDATE,
  tagId,
  payload: api.put(`/workbench/tag/${tagId}`, data)
});

export const destroy = tagId => ({
  type: DESTROY,
  tagId,
  payload: api.del(`/workbench/tag/${tagId}`)
});

export const hydrate = (filters, quiet) => {
  if (filters && filters["tags[]"] && filters["tags[]"].indexOf("processed") !== -1) {
    return hydrateProcessed(filters, quiet);
  }
  if (filters && filters["tags[]"] && filters["tags[]"].indexOf("deliveryexpired") !== -1) {
    return hydrateDeliveryExpired(filters, quiet);
  }
  return hydrateUnProcessed(filters, quiet);
};

export const hydrateUnProcessed = (filters, quiet) => ({
  type: quiet ? HYDRATE_QUIET : HYDRATE,
  payload: api.get("/workbench/tag", filters)
});

export const hydrateProcessed = (filters, quiet) => ({
  type: HYDRATE_PROCESSED,
  payload: api.get("/workbench/tag", filters)
});

export const hydrateDeliveryExpired = (filters, quiet) => ({
  type: HYDRATE_DELIVERY_EXPIRED,
  payload: api.get("/workbench/tag", filters)
});

// ------------------------------------
// Default State
// ------------------------------------

const State = Immutable.Record({
  isFetching: false,
  unprocessed: Immutable.Map({
    hasFetched: false,
    isFetching: false,
    data: Immutable.List(),
    fetchError: null,
    isUpdating: false,
    updateError: null,
    quiet: false
  }),
  processed: Immutable.Map({
    hasFetched: false,
    isFetching: false,
    data: Immutable.List(),
    fetchError: null,
    isUpdating: false,
    updateError: null,
    quiet: false
  }),
  deliveryexpired: Immutable.Map({
    hasFetched: false,
    isFetching: false,
    data: Immutable.List(),
    fetchError: null,
    isUpdating: false,
    updateError: null,
    quiet: false
  }),
  lastTagLocation: window.sessionStorage.getItem("lastTagLocation") || window.location.pathname
});

// ------------------------------------
// Action Handlers
// ------------------------------------

/**
 *
 * @param items
 * @param id
 *
 * @returns {*}
 */
const findItemIndex = (items, id) => items.findIndex(item => item.get("id") === id);

const ACTION_HANDLERS = {
  [SET_LAST_TAG_LOCATION]: (state, { payload }) => state.set("lastTagLocation", payload),
  [CREATE]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["unprocessed", "isSubmitting"], true);
      case "success": {
        const data = state.getIn(["unprocessed", "data"]);
        return state
          .setIn(["unprocessed", "isSubmitting"], false)
          .setIn(["unprocessed", "data"], data.push(Immutable.fromJS(payload)));
      }
      case "error":
        return state.setIn(["unprocessed", "isSubmitting"], false).setIn(["unprocessed", "createError"], payload);
      default:
        return state;
    }
  },
  [DESTROY]: (state, { status, tagId, payload }) => {
    const data = state.getIn(["unprocessed", "data"]);
    const itemIndex = findItemIndex(data, tagId);
    if (itemIndex === -1) {
      return state;
    }
    switch (status) {
      case "pending":
        return state.setIn(["unprocessed", "data", itemIndex, "isDeleting"], true);
      case "success": {
        return state
          .setIn(["unprocessed", "data", itemIndex, "isDeleting"], false)
          .deleteIn(["unprocessed", "data", itemIndex]);
      }
      case "error":
        return state
          .setIn(["unprocessed", "data", itemIndex, "isDeleting"], true)
          .setIn(["unprocessed", "deleteError"], payload);
      default:
        return state;
    }
  },
  [HYDRATE]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state
          .set("isFetching", true)
          .setIn(["unprocessed", "quiet"], false)
          .setIn(["unprocessed", "isFetching"], true);
      case "success":
        return state
          .set("isFetching", false)
          .setIn(["unprocessed", "isFetching"], false)
          .setIn(["unprocessed", "hasFetched"], true)
          .setIn(["unprocessed", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .set("isFetching", false)
          .setIn(["unprocessed", "isFetching"], false)
          .setIn(["unprocessed", "fetchError"], payload);
      default:
        return state;
    }
  },
  [HYDRATE_QUIET]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state
          .set("isFetching", true)
          .setIn(["unprocessed", "quiet"], true)
          .setIn(["unprocessed", "isFetching"], true);
      case "success":
        return state
          .set("isFetching", false)
          .setIn(["unprocessed", "isFetching"], false)
          .setIn(["unprocessed", "hasFetched"], true)
          .setIn(["unprocessed", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .set("isFetching", false)
          .setIn(["unprocessed", "isFetching"], false)
          .setIn(["unprocessed", "fetchError"], payload);
      default:
        return state;
    }
  },
  [HYDRATE_PROCESSED]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("isFetching", true).setIn(["processed", "isFetching"], true);
      case "success":
        return state
          .set("isFetching", false)
          .setIn(["processed", "isFetching"], false)
          .setIn(["processed", "hasFetched"], true)
          .setIn(["processed", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .set("isFetching", false)
          .setIn(["processed", "isFetching"], false)
          .setIn(["processed", "fetchError"], payload);
      default:
        return state;
    }
  },
  [HYDRATE_DELIVERY_EXPIRED]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("isFetching", true).setIn(["deliveryexpired", "isFetching"], true);
      case "success":
        return state
          .set("isFetching", false)
          .setIn(["deliveryexpired", "isFetching"], false)
          .setIn(["deliveryexpired", "hasFetched"], true)
          .setIn(["deliveryexpired", "data"], Immutable.fromJS(payload));
      case "error":
        return state
          .set("isFetching", false)
          .setIn(["deliveryexpired", "isFetching"], false)
          .setIn(["deliveryexpired", "fetchError"], payload);
      default:
        return state;
    }
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
