import Immutable from "immutable";

/**
 * Immutable Reviver: Converts JSON to List and OrderedMap; this keeps a object keys in the provided order
 *
 * @see https://facebook.github.io/immutable-js/docs/#/fromJS
 *
 * @param key
 * @param value
 *
 * @returns {*}
 */
export function ListAndOrderedMapReviver(key, value) {
  const isIndexed = Immutable.Iterable.isIndexed(value);
  return isIndexed ? value.toList() : value.toOrderedMap();
}

export default Immutable;
