import Immutable from "immutable";
import Promise from "bluebird";
import debounce from "lodash/debounce";
// eslint-disable-next-line no-unused-vars
import moment from "moment";
import { v4 as uuid } from "uuid";
import isArray from "lodash/isArray";
import { toastr } from "react-redux-toastr";

import api from "../../inc/api";

import { ping } from "./auth";
import { hydrate as hydrateUsers } from "./user";
// import { createWorker } from '../../inc/webWorker';
// import { getParams } from '../../inc/RouteUtil';
import { createListener as createCommsListener } from "../../inc/comms";
import WebSocketClient from "../../inc/WebSocketClient";
import isIncognito from "../../inc/detectIncognitoMode";

import { hydrate as hydrateOrders, hydrateOne as hydrateOrder, updateActiveOrderOpen } from "./order";
import { hydrate as hydrateTags } from "./tag";
import { getParams, filterToApiFilter } from "../../inc/RouteUtil";
import { getStore } from "../index";

// BroadcastChannel
console.log("BroadcastChannel: Initializing");
const broadcastChannel = new BroadcastChannel("workbench");
console.log("BroadcastChannel: Initialized");
broadcastChannel.onmessage = function(e) {
  console.log("BroadcastChannel: onmessage", e);
  if (e.data.type === "logout") {
    window.sessionStorage.clear();
    // kill comms
    window.DataUpdateSocket && window.DataUpdateSocket.close();
    window.ClientSocket && window.ClientSocket.close();
    // display error
    getStore().dispatch({
      type: MODAL_OVERLAY,
      payload: {
        message: "U heeft in een andere tabblad of venster uitgelogd!",
        reloadButton: true
      }
    });
  }
};

broadcastChannel.onmessageerror = function(e) {
  console.log("BroadcastChannel: onmessageerror", e);
};

export const postBroadcastChannelMessage = payload => {
  if (broadcastChannel) {
    broadcastChannel.postMessage(payload);
  }
  return {
    type: "@broadcast/MESSAGE",
    payload
  };
};

// Worker
console.log("Worker: Initializing");
let worker = new Worker("/worker.js");
worker.onmessage = function(e) {
  console.log("Message received from Worker", e.data);
};

export const postWorkerMessage = payload => {
  if (worker) {
    worker.postMessage(payload);
  }
  return {
    type: "@worker/MESSAGE",
    payload
  };
};

// SharedWorker
let sharedWorker = null;
if (window.SharedWorker) {
  console.log("SharedWorker: Initializing");
  // noinspection JSUnresolvedFunction
  let sharedWorker = new window.SharedWorker("/shared-worker.js");
  sharedWorker.port.onmessage = function(e) {
    console.log("Message received from SharedWorker", e.data);
  };
} else {
  console.log("SharedWorker: Not available");
}

export const postSharedWorkerMessage = payload => {
  if (sharedWorker) {
    sharedWorker.postMessage(payload);
  }
  return {
    type: "@shared-worker/MESSAGE",
    payload
  };
};

// ------------------------------------
// Actions
// ------------------------------------

export const WINDOW_RESIZE = "@app/WINDOW_RESIZE";
export const SET_TITLE = "@app/SET_TITLE";
export const TOGGLE_MODAL = "@app/TOGGLE_MODAL";
export const HYDRATE_SETTINGS = "@app/HYDRATE_SETTINGS";
export const HYDRATE_ENV = "@app/HYDRATE_ENV";

// ------------------------------------
// Action generators
// ------------------------------------

export const hydrateEnv = () => ({
  type: HYDRATE_ENV,
  payload: api.get("/env").then(env => {
    Object.keys(env).forEach(key => {
      process.env[key] = env[key];
    });
    return env;
  })
});

export const RESTART_APP = "@app/RESTART";

export const restartApp = () => {
  setTimeout(() => {
    window.document.location.reload();
  }, 600);
  return {
    type: MODAL_OVERLAY,
    payload: { message: "Applicatie is bijgewerkt, de applicatie wordt opnieuw ingeladen!" }
  };
};

export const INIT_MBUS = "@app/mbus/INIT";
export const initMessageBus = () => dispatch => {
  createCommsListener();
  return dispatch({
    type: INIT_MBUS
  });
};

export const isMultiUser = false;
export const masterWindow = isMultiUser ? window.parent : window;
window.masterWindow = masterWindow;
export const WINDOW_NAME = window.name || "user";

const __DATA_SOCKET_DEBUG__ = process.env.APP_DATA_SOCKET_DEBUG;
const __CLIENT_SOCKET_DEBUG__ = process.env.APP_CLIENT_SOCKET_DEBUG;

export const initDataUpdateSocket = () => (dispatch, getState) => {
  const WSS_UPDATE_URI = getState().app.getIn(["env", "WSS_UPDATE_URI"]);
  if (!masterWindow.DataUpdateSocket) {
    // eslint-disable-next-line no-console
    console.log(`Initializing WebSocket (DataUpdateSocket) @ ${WSS_UPDATE_URI}`);
    masterWindow.DataUpdateSocket = new WebSocketClient(WSS_UPDATE_URI);
    masterWindow.DataUpdateSocket.onmessage = message => {
      if (__DATA_SOCKET_DEBUG__) {
        // eslint-disable-next-line no-console
        console.trace("DataUpdateSocket", message);
      }

      const data = JSON.parse(message.data);
      const isFingerPrinted = data.fingerprint;
      const payload = isFingerPrinted ? data.payload : data;
      if (__DATA_SOCKET_DEBUG__) {
        // eslint-disable-next-line no-console
        console.trace("payload", payload);
      }

      if (payload.type === "SERVER_RESTART") {
        dispatch(restartApp());
        return;
      }

      if (payload.type === "TOAST_MESSAGE") {
        // 'light', 'message', 'info', 'success', 'warning', 'error'
        if (!toastr[payload.data.type]) {
          toastr.message(payload.data.message);
          return;
        }

        if (payload.data.title) {
          toastr[payload.data.type](payload.data.title, payload.data.message);
          return;
        }
        toastr[payload.data.type](payload.data.message);
        return;
      }

      // console.log('WebSocket.data', data);
      if (payload.type === "Site") {
        // update Site settings
        dispatch(hydrateRemoteSettings());
      }

      if (payload.type === "Order" || payload.type === "Tag") {
        // postInternalMessage('master', null, 'UPDATE_TAGS', payload);
        // postInternalMessage('master', null, 'UPDATE_ORDERS', payload);
        // postInternalMessage('master', null, 'UPDATE_ACTIVE_ORDER', payload);

        const state = getState();
        if (!state.auth.get("activeRole")) {
          // console.error('NO activeRole!!! ignoring message');
          return;
        }

        // console.log(payload.uid, state.auth.getIn(['profile', 'id']));
        if (
          !state.order.get("isFetching") &&
          !state.order.get("isFetchingQuiet") &&
          state.router.location.pathname.indexOf("/admin") === -1
        ) {
          const filter = Immutable.fromJS(getParams(state.router.location.pathname));
          if (filter.get("tags", Immutable.List()).equals(state.order.getIn(["filter", "tags"]))) {
            dispatch(hydrateOrders(filterToApiFilter(state.order.get("filter")), true));
          }
        }

        if (!state.tag.get("isFetching")) {
          dispatch(hydrateTags(filterToApiFilter(state.order.get("filter")), true));
        }

        if (!state.order.getIn(["active", "isFetching"], false)) {
          const activeId = state.order.getIn(["filter", "id"]);

          if (
            activeId === payload.data ||
            (payload.event === "objectsocket.updated.orderIds" &&
              isArray(payload.data) &&
              payload.data.indexOf(activeId) !== -1)
          ) {
            dispatch(hydrateOrder(activeId, true));
          }
        }
      }
    };
  }
  return dispatch({
    type: "@app/INIT_DATA_SOCKET"
  });
};

export const initClientWebSocket = () => (dispatch, getState) => {
  if (!masterWindow.ClientSocket) {
    const state = getState();
    const WSS_APP_URI = state.app.getIn(["env", "WSS_APP_URI"]);
    const userId = state.auth.getIn(["profile", "id"]);
    // eslint-disable-next-line no-console
    console.log(`Initializing WebSocket (ClientSocket) @ ${WSS_APP_URI}`);
    masterWindow.ClientSocket = new WebSocketClient(`${WSS_APP_URI}?key=debwpapp&userId=${userId}`);
    masterWindow.ClientSocket.onmessage = message => {
      if (__CLIENT_SOCKET_DEBUG__) {
        // eslint-disable-next-line no-console
        console.trace("ClientSocket", message);
      }

      const data = JSON.parse(message.data);
      const isFingerPrinted = data.fingerprint;
      const payload = isFingerPrinted ? data.payload : data;
      if (__CLIENT_SOCKET_DEBUG__) {
        // eslint-disable-next-line no-console
        console.trace("payload", payload);
      }
      if (payload.type === "order-open") {
        dispatch(updateActiveOrderOpen(payload.id, payload.payload));
      }
    };
    masterWindow.ClientSocket.onopen = e => {
      // eslint-disable-next-line no-shadow
      const state = getState();
      const params = getParams(state.router.location.pathname);
      // eslint-disable-next-line no-shadow
      const userId = state.auth.getIn(["profile", "id"]);
      if (params.id) {
        masterWindow.ClientSocket.send(
          JSON.stringify({
            type: "order-open",
            id: params.id,
            userId
          })
        );
      }
    };
  }
  return dispatch({
    type: "@app/INIT_CLIENT_SOCKET"
  });
};

export const INIT_APP = "@app/INIT";
export const initApp = () => (dispatch, getState) => {
  // eslint-disable-next-line no-console
  console.log("Initializing App...");

  const promises = [
    dispatch(initMessageBus()),
    dispatch(hydrateEnv()).then(env => {
      if (env.NODE_ENV !== "production" && document.title.toLowerCase().indexOf(env.NODE_ENV) === -1) {
        document.title = `[${env.NODE_ENV.toUpperCase()}] ${document.title}`;
      }
      dispatch(initDataUpdateSocket());
    }),
    dispatch(hydrateRemoteSettings()),
    dispatch(hydrateLocale()),
    dispatch(hydratePrinters()),
    dispatch(hyrateAttributes()),
    dispatch(hydrateUsers())
  ];

  if (!masterWindow.appPingInternal) {
    // gateway ping for keeping the session alive
    masterWindow.appPingInternal = window.setInterval(() => {
      dispatch(ping());
    }, 300000);
  }

  return dispatch({
    type: INIT_APP,
    payload: Promise.all(promises).then(data => {
      // eslint-disable-next-line no-console
      console.log("Application initialized!");
      return data;
    })
  });
};

export const HYDRATE_REMOTE_SETTINGS = "@app/settings/HYDRATE_REMOTE";

export const PERSIST_LOCATION = "@app/settings/PERSIST_LOCATION";
export const persistLocation = data => ({
  type: PERSIST_LOCATION,
  payload: Promise.resolve(data)
});

export const hydrateRemoteSettings = () => ({
  type: HYDRATE_REMOTE_SETTINGS,
  payload: api.get("/workbench/settings")
});

export const HYDRATE_LOCALE = "@app/locale/HYDRATE";
export const hydrateLocale = () => ({
  type: HYDRATE_LOCALE,
  payload: api.get("/workbench/locale")
});

export const HYDRATE_PRINTERS = "@app/printer/HYDRATE";
export const hydratePrinters = () => ({
  type: HYDRATE_PRINTERS,
  payload: api.get("/workbench/printer")
});

export const UPDATE_SETTINGS = "@app/settings/UPDATE";
export const updateSettings = data => ({
  type: UPDATE_SETTINGS,
  payload: api.put("/workbench/settings", data)
});

export const HYDRATE_ATTRIBUTES = "@app/attributes/HYDRATE";
export const hyrateAttributes = data => ({
  type: HYDRATE_ATTRIBUTES,
  payload: api.get("/workbench/order/attribute", data)
});

export const onWindowResize = () => ({
  type: WINDOW_RESIZE,
  payload: {
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  }
});

export const setTitle = title => ({ type: SET_TITLE, payload: title });
export const toggleModal = name => ({ type: TOGGLE_MODAL, payload: name });

const TOGGLE_SIDEBAR = "@app/TOGGLE_SIDEBAR";
export const toggleSidebar = expanded => ({
  type: TOGGLE_SIDEBAR,
  payload: !expanded
});

const INIT_STATE = "@app/INIT_STATE";
export const initAppState = payload => ({
  type: INIT_STATE,
  payload
});

export const getAllStorage = storage =>
  Object.keys(storage).reduce((archive, key) => {
    let data = storage.getItem(key);
    try {
      data = JSON.parse(data);
    } catch (err) {
      /* ignore */
    }
    archive[key] = data || storage.getItem(key);
    return archive;
  }, {});

const SEND_DEBUG_DATA = "@app/SEND_DEBUG_DATA";

export const sendDebugData = (error, sentryEventId) => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: SEND_DEBUG_DATA,
    payload: Promise.all([isIncognito(), import("html2canvas").then(module => module.default(document.body))]).spread(
      (isIncog, canvas) =>
        api.post("/workbench/debug", {
          sentryEventId,
          ctx: window.name,
          isIncognito: isIncog,
          error: error && {
            message: error.message,
            code: error.code,
            stack: error.stack
          },
          timestamp: new Date().toUTCString(),
          location: document.location,
          sessionStorage: getAllStorage(window.sessionStorage),
          localStorage: getAllStorage(window.localStorage),
          socket: {
            readyState: window.parent.DataUpdateSocket.instance && window.parent.DataUpdateSocket.instance.readyState
          },
          refreshTokensTask: window.parent.refreshTokensTask,
          state,
          screenshot: canvas.toDataURL("image/png"),
          window: {
            opener: window.opener,
            screen: window.screen,
            orientation: window.orientation,
            outerHeight: window.outerHeight,
            pageXOffset: window.pageXOffset,
            pageYOffset: window.pageYOffset,
            screenLeft: window.screenLeft,
            screenTop: window.screenTop,
            screenX: window.screenX,
            screenY: window.screenY,
            hasTouch: "ontouchstart" in window
          },
          navigator: {
            onLine: navigator.onLine,
            appCodeName: navigator.appCodeName,
            appName: navigator.appName,
            appVersion: navigator.appVersion,
            platform: navigator.platform,
            product: navigator.product,
            productSub: navigator.productSub,
            userAgent: navigator.userAgent,
            vendor: navigator.vendor,
            vendorSub: navigator.vendorSub
          }
        })
    )
  });
};

// ------------------------------------
// Default State
// ------------------------------------
const State = new Immutable.Record({
  ctxId: window.name ? window.name.replace("ctx", "") : "user",
  isInitializing: true,
  hasInitialized: false,
  initError: null,
  settings: new Immutable.Map({
    data: Immutable.fromJS(JSON.parse(window.localStorage.getItem("settings")) || {}),
    settingsInProgress: false,
    settingsError: null
  }),
  userContexts: new Immutable.Map({
    [uuid()]: null
  }),
  l10n: new Immutable.Map(),
  printers: new Immutable.Map(),
  attributes: new Immutable.Map(),
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight,
  windowHasTouch: "ontouchstart" in window,
  titleSuffix: document.title,
  title: "",
  filter: new Immutable.Map(),
  modal: new Immutable.Map(),
  env: new Immutable.Map(),
  location: new Immutable.Map(),
  MODAL_OVERLAY: new Immutable.Map()
});

export const MODAL_OVERLAY = "@app/MODAL_OVERLAY";

export const SET_LOCATION = "@app/SET_LOCATION";

export const setLocation = location => ({
  type: SET_LOCATION,
  payload: location
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  "@@router/LOCATION_CHANGE": (state, { payload }) => {
    let params = getParams(payload.location.pathname);
    const _state = state.set("filter", Immutable.fromJS(params));
    if (payload.location && payload.location.state && payload.location.state.from) {
      return _state.set("location", Immutable.fromJS(payload.location.state.from));
    }
    return _state;
  },
  [SET_LOCATION]: (state, { status, payload }) => state.set("location", payload),
  [INIT_STATE]: (state, { payload }) => Immutable.fromJS(payload).set("ctxId", state.get("ctxId")),
  [INIT_APP]: (state, { status, payload }) => {
    // eslint-disable-next-line no-console
    switch (status) {
      case "pending":
        return state.set("isInitializing", true);
      case "success":
        return state.set("isInitializing", false).set("hasInitialized", true);
      case "error":
        return state.set("isInitializing", false).set("initError", payload);
      default:
        return state;
    }
  },
  [TOGGLE_SIDEBAR]: (state, { payload }) => state.setIn(["settings", "data", "sidebarExpanded"], payload),
  [MODAL_OVERLAY]: (state, { payload }) => state.set("MODAL_OVERLAY", Immutable.fromJS(payload)),
  [HYDRATE_LOCALE]: (state, { status, payload }) => {
    switch (status) {
      case "success":
        return state.setIn(["l10n", "data"], Immutable.fromJS(payload));
      default:
        return state;
    }
  },
  [HYDRATE_ATTRIBUTES]: (state, { status, payload }) => {
    switch (status) {
      case "success":
        return state.setIn(["attributes", "data"], Immutable.fromJS(payload));
      default:
        return state;
    }
  },
  [HYDRATE_PRINTERS]: (state, { status, payload }) => {
    switch (status) {
      case "success":
        return state.setIn(["printers", "data"], Immutable.fromJS(payload));
      default:
        return state;
    }
  },
  [HYDRATE_ENV]: (state, { status, payload }) => {
    switch (status) {
      case "success":
        return state.set("env", Immutable.fromJS(payload));
      default:
        return state;
    }
  },
  [HYDRATE_REMOTE_SETTINGS]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["settings", "settingsInProgress"], true);
      case "success":
        return state
          .setIn(["settings", "settingsInProgress"], false)
          .setIn(["settings", "data"], state.getIn(["settings", "data"]).merge(Immutable.fromJS(payload)));
      case "error":
        return state.setIn(["settings", "settingsInProgress"], false).setIn(["settings", "settingsError"], payload);
      default:
        return state;
    }
  },
  [PERSIST_LOCATION]: (state, { status, payload }) => {
    switch (status) {
      case "success":
        return state.setIn(["settings", "data", "location"], Immutable.fromJS(payload));
      default:
        return state;
    }
  },
  [WINDOW_RESIZE]: (state, { payload }) =>
    state.set("windowWidth", payload.windowWidth).set("windowHeight", payload.windowHeight),
  [SET_TITLE]: (state, { payload }) => {
    document.title = `${state.get("title")} - ${state.get("titleSuffix")}`;
    return state.set("title", payload);
  },
  [TOGGLE_MODAL]: (state, { payload }) => state.setIn(["modal", payload], !state.getIn(["modal", payload], false))
};

const persistStateDebounce = debounce(state => {
  window.localStorage.setItem("settings", JSON.stringify(state.getIn(["settings", "data"]).toJS()));
}, 50);

const persistState = state => {
  persistStateDebounce(state);
  return state;
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? persistState(handler(state, action)) : state;
}
