import { TAG_ARRANGE, TAG_PACKAGE } from "./constants";

export const getParams = pathname => {
  const params = {};
  if (pathname === "/order") {
    return {
      tags: ["unprocessed"]
    };
  }
  if (pathname === "/arranger") {
    return {
      tags: ["unprocessed", TAG_ARRANGE]
    };
  }
  if (pathname === "/packager") {
    return {
      tags: ["unprocessed", TAG_PACKAGE]
    };
  }
  const parts = (pathname || document.location.pathname).replace(/^\//, "").split("/");
  parts.forEach(part => {
    // 'test'.match(/^te(st)/)
    let matches = part.match(/^q\[(.+?)\]$/i); // eslint-disable-line no-useless-escape
    if (matches) {
      // params.query = decodeURIComponent(matches[1]);
      params.query = matches[1];
      params.tags = params.tags || [];
    }

    matches = part.match(/^t\[([\w,]+)\]$/i); // eslint-disable-line no-useless-escape
    if (matches) {
      params.tags = matches[1].split(",").map(tag => {
        const n = parseInt(tag, 10);
        return isFinite(n) ? n : tag;
      });
    }
    if (!params.tags || !params.tags.length) {
      // params.tags = ['unprocessed'];
    }
    matches = part.match(/^p\[([\d]+)\]$/); // eslint-disable-line no-useless-escape
    if (matches && matches[1] !== "1") {
      const page = parseInt(matches[1], 10);
      if (page !== 1) {
        params.page = parseInt(matches[1], 10);
      }
    }
    matches = part.match(/^(\d+)$/);
    if (matches) {
      params.id = parseInt(matches[1], 10);
    }
  });

  if (/^\/(arranger|packager)(process)?\//.test(pathname)) {
    params.forceTag = 1;
  }
  return params;
};

export const buildUrl = newParams => {
  const parts = [newParams.get("urlPrefix") || "/order"];

  const tags = newParams.get("tags");
  if (tags) {
    parts.push(`t[${tags.join(",")}]`);
  }

  const query = newParams.get("query");
  if (query) {
    // parts.push(`q[${encodeURIComponent(query)}]`);
    parts.push(`q[${query}]`);
  }

  const page = newParams.get("page");
  if (page) {
    parts.push(`p[${page}]`);
  }

  const id = newParams.get("id");
  if (id) {
    parts.push(id);
  }

  const action = newParams.get("action");
  if (action) {
    parts.push(action);
  }

  return parts.join("/");
};

export const filterToApiFilter = filter =>
  filter
    .delete("id")
    .set("tags[]", filter.get("tags"))
    .delete("tags")
    .set("q", filter.get("query"))
    .delete("query")
    .toJS();

export const buildIdUrl = (filter, id) => buildUrl(filter.set("id", id));
