import isArray from "lodash/isArray";

import { getStore } from "../store";

import { initAppState } from "../store/modules/app";
import { initUserState } from "../store/modules/user";

// import { updateToken } from '../store/modules/auth';
import { updateTokens } from "../store/modules/auth";

import { hydrate as hydrateOrders, hydrateOne as hydrateOrder } from "../store/modules/order";
import { hydrate as hydrateTags } from "../store/modules/tag";
import { filterToApiFilter } from "./RouteUtil";

const IDENTIFIER = "@app-workbench";
const IDENTIFIER_RE = /^@app-workbench\/(.*)/;

const WIND_NAME = (window.name && window.name.replace("ctx", "")) || "master";

const __DEV__ = process.env.NODE_ENV !== "production";

function receiveMessage(e) {
  if (e.origin !== document.location.origin || !e.data || !e.data.source || !e.data.target) {
    return;
  }
  let source = e.data.source.match(IDENTIFIER_RE);
  if (source === null) {
    return;
  }
  source = source[1];
  let target = e.data.target.match(IDENTIFIER_RE);
  if (target === null) {
    return;
  }
  target = target[1];

  if (source === WIND_NAME) {
    return;
  }

  const payload = e.data.payload;

  // console.log(`GOT MESSAGE to ${target} from ${source} `, e);
  if (e.data.type === "GET_APP_STATE") {
    const state = getStore().getState();
    postInternalMessage(target, source, "RECEIVE_APP_STATE", {
      app: state.app.toJS(),
      user: state.user.toJS()
    });
  }
  if (e.data.type === "RECEIVE_APP_STATE") {
    getStore().dispatch(initAppState(payload.app));
    // console.log(e.data.payload);
    getStore().dispatch(initUserState(payload.user));
  }
  if (e.data.type === "UPDATE_TOKEN") {
    getStore().dispatch(updateTokens(source, payload));
    // postInternalMessage(target, source, 'RECEIVE_UPDATE_TOKEN', getStore().getState().app.toJS());
  }

  if (target !== "master") {
    const store = getStore();
    const state = store.getState();
    if (!state.auth.get("activeRole")) {
      return;
    }

    if (e.data.type === "UPDATE_TAGS" && !state.tag.get("isFetching")) {
      getStore().dispatch(hydrateTags(filterToApiFilter(state.order.get("filter")), true));
    }

    if (e.data.type === "UPDATE_ORDERS" && !state.order.get("isFetching", false)) {
      getStore().dispatch(hydrateOrders(filterToApiFilter(state.order.get("filter")), true));
    }

    if (e.data.type === "UPDATE_ACTIVE_ORDER" && !state.order.getIn(["active", "isFetching"], false)) {
      const activeId = state.order.getIn(["filter", "id"]);

      if (
        activeId === payload.data ||
        (payload.event === "objectsocket.updated.orderIds" &&
          isArray(payload.data) &&
          payload.data.indexOf(activeId) !== -1)
      ) {
        getStore().dispatch(hydrateOrder(activeId, true));
      }
    }
  }
}

export const postInternalMessage = (source, target, type, payload) => {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.log("postInternalMessage", source, target, type, payload);
  }
  if (source === "master" && target === null) {
    getStore()
      .getState()
      .auth.get("tokens")
      .keySeq()
      .toArray()
      .forEach(key => {
        postInternalMessage(source, key, type, payload);
      });
    return;
  }
  // console.log('postInternalMessage', source, target, type, payload, document.location.origin);
  const toWindow = window[target === "master" ? "parent" : `ctx${target}`];
  if (!toWindow) {
    return;
  }
  toWindow.postMessage(
    {
      appId: IDENTIFIER,
      source: `${IDENTIFIER}/${source || WIND_NAME}`,
      target: `${IDENTIFIER}/${target}`,
      type,
      payload
    },
    document.location.origin
  );
};

export const createListener = () => {
  if (!window.hasCommsListener) {
    // console.log('CREATING MESSAGE LISTENER FOR: ', window.name || 'master');
    window.addEventListener("message", receiveMessage, false);
    window.hasCommsListener = true;
  }
};

export default {};

/*
  window.postMessage({
    type: 'GET_GLOBAL_SETTINGS',
    source: '@app-workbench/master',
    target: '@app-workbench/ID',
    payload: {}
  }, document.location.origin);
 */
