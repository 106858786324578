// Detect private browsing
// Inpired by original gist: https://gist.github.com/cou929/7973956
// But based in general on comment: https://gist.github.com/cou929/7973956#gistcomment-1791792 and other comments

export default function isIncognito() {
  return new Promise(resolve => {
    const on = () => resolve(true);
    const off = () => resolve(false);

    // Webkit() {
    const fs = window.requestFileSystem || window.RequestFileSystem || window.webkitRequestFileSystem;
    if (fs) {
      fs(window.TEMPORARY, 100, off, on);
    }

    // Mozilla
    if ("MozAppearance" in document.documentElement.style) {
      const db = indexedDB.open("test");
      db.onerror = on;
      db.onsuccess = off;
    }

    // Safari()
    if (/constructor/i.test(window.HTMLElement)) {
      // iOS 11
      // Origin: https://gist.github.com/cou929/7973956#gistcomment-2272103
      try {
        window.openDatabase(null, null, null, null);
      } catch (e) {
        on();
      }

      // Older Safari
      try {
        if (localStorage.length) {
          off();
        }
        localStorage.x = 1;
        localStorage.removeItem("x");
        on();
      } catch (e) {
        // Original gist: https://gist.github.com/jherax/a81c8c132d09cc354a0e2cb911841ff1

        // Safari only enables cookie in private mode
        // if cookie is disabled then all client side storage is disabled
        // if all client side storage is disabled, then there is no point
        // in using private mode
        if (navigator.cookieEnabled) {
          on();
        }
      }
    }

    // IE10Edge
    if (!window.indexedDB && (window.PointerEvent || window.MSPointerEvent)) {
      on();
    }
    off();
  });
}

// window.isPrivate = function (on_cb, off_cb) {
//   on = on_cb || function () {};
//   off = off_cb || function () {};
//   Webkit() || Mozilla() || Safari() || IE10Edge() || off();
// };

// // Example of usage
// isPrivate(
//   function () {document.body.innerHTML = 'Private browsing: <b>ON</b>'},
//   function () {document.body.innerHTML = 'Private browsing: <b>OFF</b>'}
// );
