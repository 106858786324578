// import uuid from 'uuid';

import Immutable, { ListAndOrderedMapReviver } from "../../inc/Immutable";
import api from "../../inc/api";

// ------------------------------------
// Actions
// ------------------------------------

export const ORDER_UNDELETE = "@app/admin/ORDER_UNDELETE";

export const CLEAR_ORDER_UNDELETE = "@app/admin/CLEAR_ORDER_UNDELETE";

export const HYDRATE_ALL_STATISTICS = "@app/admin/HYDRATE_ALL_STATISTICS";
export const HYDRATE_STATISTIC = "@app/admin/HYDRATE_STATISTIC";
export const HYDRATE_TRANSPORT_STATISTICS = "@app/admin/HYDRATE_TRANSPORT_STATISTICS";
export const HYDRATE_PAYMENTMETHOD_STATISTICS = "@app/admin/HYDRATE_PAYMENTMETHOD_STATISTICS";

// ------------------------------------
// Action Generators
// ------------------------------------

export const undeleteOrder = id => ({
  type: ORDER_UNDELETE,
  payload: api.post(`/workbench/order/${id}/undelete`)
});

export const BARCODE_GENERATE_PDF = "@app/admin/BARCODE_GENERATE_PDF";
export const generateBarcodePdf = data => ({
  type: BARCODE_GENERATE_PDF,
  payload: api.get("/workbench/barcode").query(data)
});

export const BARCODE_GENERATE_PNG = "@app/admin/BARCODE_GENERATE_PNG";
export const generateBarcodePng = data => ({
  type: BARCODE_GENERATE_PNG,
  payload: api.get("/workbench/barcode/png").query(data)
});

export const clearUndeleteOrder = () => ({ type: CLEAR_ORDER_UNDELETE });

export const hydrateStatistic = name => ({
  type: HYDRATE_STATISTIC,
  name,
  payload: api.get(`/workbench/stats/${name}`)
});

export const hydrateStatistics = () => dispatch =>
  dispatch({
    type: HYDRATE_ALL_STATISTICS,
    payload: Promise.all([dispatch(hydrateTransportStatistics()), dispatch(hydratePaymentMethodStatistics())])
  });

export const hydrateTransportStatistics = () => dispatch =>
  dispatch({
    type: HYDRATE_TRANSPORT_STATISTICS,
    payload: api.get("/workbench/stats/transporter")
  });

export const hydratePaymentMethodStatistics = () => dispatch =>
  dispatch({
    type: HYDRATE_PAYMENTMETHOD_STATISTICS,
    payload: api.get("/workbench/stats/paymentMethod")
  });

// ------------------------------------
// Initial State
// ------------------------------------
const State = new Immutable.Record({
  undelete: Immutable.Map({
    isProcessing: false,
    data: 0,
    error: ""
  }),
  barcode: Immutable.Map({
    isProcessing: false,
    data: "",
    error: null
  }),
  stats: Immutable.Map({
    transporter: Immutable.Map({
      isFetching: false,
      hasFetched: false,
      data: Immutable.List(),
      error: ""
    }),
    paymentMethod: Immutable.Map({
      isFetching: false,
      hasFetched: false,
      data: Immutable.List(),
      error: ""
    })
  })
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [ORDER_UNDELETE]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["undelete", "isProcessing"], true);
      case "success": {
        return state.setIn(["undelete", "isProcessing"], false).setIn(["undelete", "data", Immutable.fromJS(payload)]);
      }
      case "error":
        return state.setIn(["undelete", "isProcessing"], false).setIn(["undelete", "error"], payload);
      default:
        return state;
    }
  },
  [HYDRATE_TRANSPORT_STATISTICS]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["stats", "transporter", "isFetching"], true);
      case "success": {
        return state
          .setIn(["stats", "transporter", "isFetching"], false)
          .setIn(["stats", "transporter", "hasFetched"], true)
          .setIn(["stats", "transporter", "data"], Immutable.fromJS(payload, ListAndOrderedMapReviver));
      }
      case "error":
        return state
          .setIn(["stats", "transporter", "isFetching"], false)
          .setIn(["stats", "transporter", "error"], Immutable.fromJS(payload));
      default:
        return state;
    }
  },
  [HYDRATE_PAYMENTMETHOD_STATISTICS]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["stats", "paymentMethod", "isFetching"], true);
      case "success": {
        return state
          .setIn(["stats", "paymentMethod", "isFetching"], false)
          .setIn(["stats", "paymentMethod", "hasFetched"], true)
          .setIn(["stats", "paymentMethod", "data"], Immutable.fromJS(payload, ListAndOrderedMapReviver));
      }
      case "error":
        return state
          .setIn(["stats", "paymentMethod", "isFetching"], false)
          .setIn(["stats", "paymentMethod", "error"], Immutable.fromJS(payload));
      default:
        return state;
    }
  },
  [HYDRATE_STATISTIC]: (state, { status, name, payload }) => {
    switch (status) {
      case "pending":
        return state.setIn(["stats", name, "isFetching"], true);
      case "success": {
        return state
          .setIn(["stats", name, "isFetching"], false)
          .setIn(["stats", name, "isLoaded"], true)
          .setIn(["stats", name, "name"], payload.name)
          .setIn(["stats", name, "data"], Immutable.fromJS(payload.data, ListAndOrderedMapReviver));
      }
      case "error":
        return state
          .setIn(["stats", name, "isFetching"], false)
          .setIn(["stats", name, "error"], Immutable.fromJS(payload.err));
      default:
        return state;
    }
  }
};

// ------------------------------------
// Selectors
// ------------------------------------

// ------------------------------------
// Reducer
// ------------------------------------
export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
