import React, { Suspense, lazy, Component } from "react";
import { Switch, Route } from "react-router";

import "./layout/style/index.scss";

import AuthedRoute from "./component/AuthedRoute";

const AuthLoginContainer = lazy(() => import("./view/Auth/Login"));
const AuthLogoutContainer = lazy(() => import("./view/Auth/Logout"));
const AuthResetContainer = lazy(() => import("./view/Auth/Reset"));

const AppRoute = lazy(() => import("./AppRoot"));
const NotFoundContainer = lazy(() => import("./layout/NotFound"));

class Bootstrap extends Component {
  static propTypes = {};

  static defaultProps = {};

  render() {
    return (
      <Suspense fallback={null}>
        <Switch>
          <Route path="/auth/login" exact component={AuthLoginContainer} />
          <Route path="/auth/reset" exact component={AuthResetContainer} />
          <Route path="/auth/logout" exact component={AuthLogoutContainer} />
          <AuthedRoute path="/" component={AppRoute} />
          <Route path="*" component={NotFoundContainer} />
        </Switch>
      </Suspense>
    );
  }
}

export default Bootstrap;
