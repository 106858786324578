import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as reduxForm } from "redux-form";
import { reducer as notifications } from "react-notification-system-redux";
import { reducer as toastr } from "react-redux-toastr";

import app from "./modules/app";
import auth from "./modules/auth";
import api from "./modules/api";
import user from "./modules/user";
import order from "./modules/order";
import tag from "./modules/tag";
import admin from "./modules/admin";
import bulkimport from "./modules/import";
import products from "./modules/products";

export const makeRootReducer = (history, asyncReducers, reduxFormReducerPlugins) =>
  combineReducers({
    ...asyncReducers,
    auth,
    app,
    order,
    tag,
    api,
    user,
    admin,
    bulkimport,
    products,
    toastr,
    form: reduxForm.plugin({ ...reduxFormReducerPlugins }),
    router: connectRouter(history),
    notifications
  });

export const injectReducer = (store, { key, reducer }) => {
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.history, store.asyncReducers, store.reduxFormReducerPlugins));
};

const reduxFormReducerPlugins = {};
export const injectReduxFormReducerPlugin = (store, { form, reducer }) => {
  if (!reduxFormReducerPlugins[form]) {
    reduxFormReducerPlugins[form] = [reducer];
  } else {
    reduxFormReducerPlugins[form].push(reducer);
  }
  store.reduxFormReducerPlugins[form] = (state, action) => {
    reduxFormReducerPlugins[form].forEach(r => {
      state = r(state, action);
    });
    return state;
  };
  store.replaceReducer(makeRootReducer(store.history, store.asyncReducers, store.reduxFormReducerPlugins));
};

export default makeRootReducer;
