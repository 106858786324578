import Immutable from "immutable";
import api from "../../inc/api";

// ------------------------------------
// Actions
// ------------------------------------

export const HYDRATE = "@app/user/HYDRATE";

// ------------------------------------
// Action generators
// ------------------------------------

export const hydrate = data => ({ type: HYDRATE, payload: api.get("/workbench/user").ctx() });

const INIT_STATE = "@app/user/INIT_STATE";
export const initUserState = payload => ({
  type: INIT_STATE,
  payload
});

const State = Immutable.Record({
  isFetching: false,
  isFetched: false,
  data: Immutable.List(),
  error: null
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [INIT_STATE]: (state, { payload }) => Immutable.fromJS(payload),
  [HYDRATE]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("isFetching", true);
      case "success":
        return state
          .set("isFetching", false)
          .set("isFetched", true)
          .set("data", Immutable.fromJS(payload));
      case "error":
        return state.set("isFetching", false).set("error", payload);
      default:
        return state;
    }
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
