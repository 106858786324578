import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";

import { getStore } from "../../store";
import { sendDebugData } from "../../store/modules/app";

import "./ErrorBoundary.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  static propTypes = {
    children: PropTypes.node
  };
  static defaultProps = {
    children: null
  };

  state = {
    error: null,
    errorInfo: null,
    eventId: null
  };

  onClickShowErrorDialog = e => {
    if (Sentry.lastEventId()) {
      e.preventDefault();
      const cfg = {};
      const state = getStore().getState();
      if (state.auth && state.auth.getIn(["profile", "email"])) {
        cfg.email = state.auth.getIn(["profile", "email"]);
        cfg.name = [
          state.auth.getIn(["profile", "firstName"]),
          state.auth.getIn(["profile", "middleName"]),
          state.auth.getIn(["profile", "lastName"])
        ]
          .filter(v => !!v)
          .join(" ");
      }
      Sentry.showReportDialog(cfg);
    }
    getStore().dispatch(sendDebugData(this.state.error, this.state.eventId));
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({eventId});
    });
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <div className="d-flex flex-column align-items-center ErrorBoundary modal-container">
          <i className="ErrorBoundary__Warning fa fa-warning" />

          <p>
            <strong>Er is iets vreselijks gebeurt. Dit hoort ook niet te gebeuren!</strong>
          </p>

          <div>
            Wij zijn ervan op de hoogte, je kan ook hier klikken en een rapport insturen.
            <div className="d-flex flex-column justify-content-center align-items-center mt-3">
              <button onClick={this.onClickShowErrorDialog}>
                <i className="fa fa-bug mr-2" />
                Verstuur debug data
              </button>
            </div>
          </div>

          <details>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
