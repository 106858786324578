// Wrapper class
class WebWorkerPromise {
  promises = [];
  msgId = 0;

  constructor(webWorkerUri) {
    this.worker = new Worker(webWorkerUri);
    this.worker.onmessage = (msg) => {
      const {id, error, payload} = msg.data
      if (!this.promises[id]) {
        return;
      }
      if (payload) {
        this.promises[id].resolve(payload);
      } else {
        // error condition
        this.promises[id].reject(error || new Error("Got nothing"));
      }
      // clean up
      delete this.promises[id]
    };
  }

  postMessage(payload) {
    const msgId = this.msgId++
    const msg = {
      id: msgId,
      payload
    }

    let resolve, reject, promise = new Promise((_resolve, _reject) => {
      resolve = _resolve;
      reject = _reject;
      this.worker.postMessage(msg);
    });

    promise.resolve = resolve;
    promise.reject = reject

    this.promises[msgId] = promise;

    return this.promises[msgId];
  }

}

export default WebWorkerPromise;
