import Immutable from "immutable";
import Bluebird from "bluebird";

import WebWorkerPromise from "../../inc/WebWorkerPromise";
import api from "../../inc/api";
import merge from "lodash/merge";

// import { reduxFormReducerPlugins } from "../../store";

const xlsxWorker = new WebWorkerPromise("/xlsxworker.js");

// XLSX v1.0.0 (nl) password "5ydQGpEqcYmxFe4HS6UQL5o7VTryTHXojbttKPwyMVeVnHfV"

const xlsFieldMap = {
  customer: {
    Contactpersoon: "customer.lastName",
    Bedrijfsnaam: "customer.companyName",
    "KvK nummer": "customer.cocNumber",
    Straat: "customer.street",
    Huisnummer: "customer.streetNumber",
    Postcode: "customer.zipcode",
    Plaats: "customer.city",
    Telefoonnummer: "customer.phone",
    "E-mailadres": "customer.emailAddress",
    Product: "items.0.productCollectionMemberId",
    Kaarttekst: "card.text"
  },
  recipient: {
    "Naam ontvanger": "address.fullName",
    Bedrijfsnaam: "address.addition1",
    Straat: "address.street",
    Huisnummer: "address.streetNumber",
    Postcode: "address.zipcode",
    Plaats: "address.city",
    Land: "address.countryCode",
    Telefoonnummer: "address.phone",
    "E-mailadres": "address.emailAddress",
    Kaarttekst: "card.text"
  }
};

// ------------------------------------
// Actions
// ------------------------------------

export const XLSX2JSON = "@import/XLSX2JSON";
export const HYDRATE_SCHEMA = "@import/HYDRATE_SCHEMA";
export const CREATE_ORDERS = "@import/CREATE_ORDERS";
export const CREATE_ORDER = "@import/CREATE_ORDER";
export const SET_CURRENT_STEP = "@import/SET_CURRENT_STEP";
export const RESET_IMPORT = "@import/RESET";
// export const IMPORT = "@import/IMPORT";

// ------------------------------------
// Action generators
// ------------------------------------

export const setCurrentStep = step => ({
  type: SET_CURRENT_STEP,
  payload: step
});

export const clearImport = () => {
  // window.localStorage.removeItem(currentImportStorageKey);
  return {
    type: RESET_IMPORT
  };
};

// const timeoutPromise = (timeout = 600) =>
//   new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve({});
//     }, timeout);
//   });

// timeoutPromise() //
export const createOrder = (orderData, idx) => {
  return {
    type: CREATE_ORDER,
    idx,
    payload: api.post("/workbench/order/bulkImport", orderData)
  };
};

// Let us open our database
let db;
const request = window.indexedDB.open("BulkImport", 3);
request.onerror = function(event) {
  console.log("Why didn't you allow my web app to use IndexedDB?!");
};
request.onsuccess = function(event) {
  db = event.target.result;
  db.onerror = function(event) {
    // Generic error handler for all errors targeted at this database's
    // requests!
    console.error("Database error: " + event.target.errorCode);
  };
};

export const createOrders = data => (dispatch, getState) => {
  const state = getState();

  const formData = state.form.bulkimport.values;

  const productCollectionMemberId = formData.items[0].productCollectionMemberId;

  const webProductId =
    state.products?.webProducts?.data.find(i => i.productCollectionMemberId === productCollectionMemberId)
      ?.webProductId ?? 0;

  const orders = formData.recipients.map(recipient => {
    const cartData = {
      // dryRun: true,
      batchedOrderId: 0,
      meta: merge({}, formData.meta),
      items: [
        {
          ...formData.items[0],
          webProductId
        }
      ],
      customer: merge(
        {
          countryCode: "NL"
        },
        formData.customer
      ),
      recipients: [
        {
          address: merge(
            {
              addressType: "residence",
              countryCode: "NL"
            },
            recipient.address
          ),
          delivery: merge({}, formData.delivery),
          card: merge({}, formData.card, recipient.card || {}),
          products: [
            {
              webProductId,
              productCollectionMemberId: formData.items[0].productCollectionMemberId,
              quantity: formData.items[0].quantity,
              customPrice: formData.items[0].customPrice
            }
          ]
        }
      ],
      checkout: merge({}, formData.checkout)
    };
    return cartData;
  });

  const currentImportStatus = JSON.parse(window.localStorage.getItem(currentImportStorageKey));
  console.log(currentImportStatus);

  window.onbeforeunload = e => {
    console.log("window.onbeforeunload");
    e.stopPropagation();
    e.preventDefault();
    // window.alert("Import is bezig");
    e.returnValue = "Import is bezig!!!";
    return "Import is bezig!!!";
  };

  console.log("creating orders", orders.length);

  let batchedOrderId = 0;
  return dispatch({
    type: CREATE_ORDERS,
    count: orders.length,
    payload: Bluebird.each(orders, (orderData, idx) => {
      console.log("creating order", idx, orderData);
      orderData.batchedOrderId = batchedOrderId;
      return dispatch(createOrder(orderData, idx))
        .then(result => {
          console.log("created order", idx, result);
          orderData.orderId = result.orderId;
          if (!batchedOrderId) {
            batchedOrderId = result.orderId;
            orderData.batchedOrderId = batchedOrderId;
          }
          console.log("batchedOrderId", batchedOrderId);
          window.localStorage.setItem(currentImportStorageKey, JSON.stringify(orders));
          return result;
        })
        .catch(err => {
          console.error("created order", idx, err);
          orderData.error = err;
          // ignore
        });
    })
      .then(() => {
        window.onbeforeunload = null;
        window.localStorage.setItem(currentImportStorageKey, JSON.stringify(orders));
      })
      .catch(err => {
        window.localStorage.setItem(currentImportStorageKey, JSON.stringify(orders));
        console.error("created orders", err);
        window.onbeforeunload = null;
      })
  });
};

let currentImportStorageKey = "currentImport";

const rABS = typeof FileReader !== "undefined" && (FileReader.prototype || {}).readAsBinaryString;
export const hydrateXlsx2Json = file => ({
  type: XLSX2JSON,
  payload: new Promise((resolve, reject) => {
    console.log("converting file to json");

    currentImportStorageKey = `${file.lastModified}_${file.name}`;

    const reader = new FileReader();
    reader.onload = function(e) {
      resolve(!rABS ? new Uint8Array(e.target.result) : e.target.result);
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  })
    .then(data =>
      xlsxWorker.postMessage({
        type: rABS ? "binary" : "array",
        data
      })
    )
    .then(xlsxData => {
      // TODO apply transform by version/language
      // THIS is 1.0.0/nl
      if (!xlsxData.Opdrachtgever) {
        throw new Error("Ongeldig bestand!");
      }

      console.log("xlsxData", xlsxData);

      // transform this sheet data to what we want sheet data
      xlsxData.meta = (xlsxData.meta ?? []).reduce((meta, item) => {
        meta = item;
        return meta;
      }, {});

      let data = Immutable.fromJS({
        batchedOrderId: 0,
        items: [
          {
            webProductId: 1,
            productCollectionMemberId: 0,
            quantity: 1
          }
        ],
        customer: {
          type: "company",

          companyName: "",
          cocNumber: "",

          lastName: "",

          street: "",
          streetNumber: "",
          zipcode: "",
          city: "",
          countryCode: "NL",

          emailAddress: "",
          phone: ""
        },
        recipients: [],
        checkout: {
          payment: {
            type: 23681673, // invoice by post
            invoiceReference: ""
          },
          termsAndConditions: true
        },
        card: {
          type: 3587245, // free hat
          text: ""
        },
        delivery: {
          date: "",
          messageType: 1,
          timeFrame: 14645965 // whole day
        }
      });

      console.log("xlsxData", xlsxData);

      // transform this sheet data to what we want sheet data
      xlsxData.Opdrachtgever.forEach(item => {
        const values = Object.values(item);

        values[0] = values[0]?.trim ? values[0].trim().replace(/\s*?\*$/, "") : values[0];

        if (values[0] === "Kaarttekst" && `${values[1] ?? ""}`.startsWith("* Mits ingesteld")) {
          return;
        }
        values[0] = xlsFieldMap.customer[values[0]] ?? values[0];

        data = data.setIn(values[0].split("."), `${values[1] ?? ""}`.trim()); // always as string
      });

      if (!data.getIn(["customer", "zipcode"])) {
        throw new Error("Ongeldig ingevuld bestand; Opdrachtgever heeft geen postcode opgegeven!");
      }

      if (!data.getIn(["customer", "streetNumber"])) {
        throw new Error("Ongeldig ingevuld bestand; Opdrachtgever heeft geen huisnummer opgegeven!");
      }

      // transform this sheet data to what we want sheet data
      xlsxData.Bezorginformatie.forEach((recipient, idx) => {
        Object.keys(recipient).forEach(key => {
          const path = ["recipients", idx].concat(
            (xlsFieldMap.recipient[key.trim().replace(/\s*?\*$/, "")] ?? key).split(".")
          );
          let value = `${recipient[key] ?? ""}`.trim();
          if (key === "Land*") {
            if (value.toLowerCase() === "nederland" || value.toLowerCase().endsWith("netherlands")) {
              value = "NL";
            }
            if (value === "BE" || value.toUpperCase() === "belgie" || value.toLowerCase() === "belgië") {
              value = "BE";
            }
          }
          data = data.setIn(path, value); // always as string
        });
        data = data.setIn(`recipients.${idx}.address.addressType`.split("."), "residence");
      });

      // console.log(data.toJS());
      if (data.get("recipients").size === 0) {
        throw new Error("Ongeldig ingevuld bestand; Er zijn geen bezorggegevens!");
      }

      if (
        data.getIn(["card", "text"], "").trim().length === 0 &&
        data.get("recipients").filter(r => r.getIn(["card", "text"])).size === 0
      ) {
        data = data.setIn(["card", "type"], 0);
      }
      return data
        .set(
          "meta",
          Immutable.fromJS({
            source: "bulkimport",
            ...xlsxData.meta
          })
        )
        .toJS();
    })
});

export const hydrateSchema = () => ({ type: HYDRATE_SCHEMA, payload: api.get("/cart/schema") });

// ------------------------------------
// Default State
// ------------------------------------

const State = Immutable.Record({
  data: Immutable.Map(),
  isConverting: false,
  convertError: null,
  hasConverted: false,
  schema: Immutable.Map(),
  isHydratingSchema: false,
  hasHydratedSchema: false,
  hydrateSchemaError: null,
  imported: Immutable.List(),
  currentStep: 0
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [SET_CURRENT_STEP]: (state, { payload }) => {
    return state.set("currentStep", payload);
  },
  [RESET_IMPORT]: () => {
    return new State();
  },
  [CREATE_ORDER]: (state, { status, idx, payload }) => {
    const path = `imported.${idx}`;
    switch (status) {
      case "pending":
        return state.setIn(`${path}.isImporting`.split("."), true);
      case "success":
        return state
          .setIn(`${path}.isImporting`.split("."), false)
          .setIn(`${path}.isImported`.split("."), true)
          .setIn(`${path}.data`.split("."), Immutable.fromJS(payload));
      case "error":
        return state.setIn(`${path}.isImporting`.split("."), false).setIn(`${path}.error`.split("."), payload);
      default:
        return state;
    }
  },
  [XLSX2JSON]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("imported", new Immutable.List()).set("isConverting", true);
      case "success":
        return state
          .set("isConverting", false)
          .set("hasConverted", true)
          .set("data", Immutable.fromJS(payload));
      case "error":
        return state.set("isConverting", false).set("convertError", payload);
      default:
        return state;
    }
  },
  [HYDRATE_SCHEMA]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("isHydratingSchema", true);
      case "success":
        return state
          .set("isHydratingSchema", false)
          .set("hasHydratedSchema", true)
          .set("schema", Immutable.fromJS(payload));
      case "error":
        return state.set("isHydratingSchema", false).set("hydrateSchemaError", payload);
      default:
        return state;
    }
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
