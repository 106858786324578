import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { clearTokens } from "../store/modules/auth";

class AuthedRoute extends React.Component {
  static propTypes = {
    isLoggedIn: PropTypes.bool,
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
    clearTokens: PropTypes.func.isRequired
  };

  static defaultProps = {
    isLoggedIn: false,
    location: null,
    component: null,
    render: null
  };

  componentDidMount() {
    if (!this.props.isLoggedIn) {
      this.props.clearTokens();
    }
  }

  render() {
    if (!this.props.isLoggedIn) {
      return (
        <Redirect
          to={{
            pathname: "/auth/login",
            state: { from: this.props.location }
          }}
        />
      );
    }
    return React.createElement(Route, this.props);
  }
}

export default connect(
  state => ({
    isLoggedIn: !!state.auth.getIn(["tokens", "master", "token"], null)
  }),
  {
    clearTokens
  }
)(AuthedRoute);
