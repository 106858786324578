import { LOCATION_CHANGE } from "connected-react-router";
import { getParams } from "../../inc/RouteUtil";
import { masterWindow } from "../modules/app";

// https://stackoverflow.com/questions/36730793/can-i-dispatch-an-action-in-reducer

export default function(store, two, three) {
  return next => action => {
    if (action.type !== LOCATION_CHANGE || !masterWindow.ClientSocket) {
      return next(action);
    }

    if (action.payload.location.state) {
      if (action.payload.location.pathname.length > 1) {
        const prefix = action.payload.location.pathname.split("/")[1];
        // const params = getParams(action.payload.location.state.from.pathname);
        window.sessionStorage.setItem(`${prefix}.lastTagLocation`, action.payload.location.state.from.pathname);
      }
    }

    // when a user opens a order details url then we want to inform the socket that the user has opened it
    const userId = store.getState().auth.getIn(["profile", "id"]);
    let params = getParams(action.payload.location.pathname);
    if (params.id) {
      masterWindow.ClientSocket.send(
        JSON.stringify({
          type: "order-open",
          id: params.id,
          userId
        })
      );
    }

    // when a user closes a order details url then we want to inform the socket that the user has closed it
    const previousLocation = store.getState().router.location;
    if (previousLocation && previousLocation.pathname) {
      params = getParams(previousLocation.pathname);
      if (params.id) {
        masterWindow.ClientSocket.send(
          JSON.stringify({
            type: "order-close",
            id: params.id,
            userId
          })
        );
      }
    }

    return next(action);
  };
}
