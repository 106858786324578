import Immutable from "immutable";
import api from "../../inc/api";

// ------------------------------------
// Actions
// ------------------------------------

export const HYDRATE = "@app/api/HYDRATE";

// ------------------------------------
// Action generators
// ------------------------------------

export const hydrate = () => ({ type: HYDRATE, payload: api.get("/") });

// ------------------------------------
// Default State
// ------------------------------------

const State = Immutable.Record({
  schema: Immutable.Map(),
  isFetching: false,
  hasFetched: false,
  fetchError: null
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [HYDRATE]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("isFetching", true);
      case "success":
        return state
          .set("isFetching", false)
          .set("hasFetched", true)
          .set("schema", Immutable.fromJS(payload));
      case "error":
        return state.set("isFetching", false).set("fetchError", payload);
      default:
        return state;
    }
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
