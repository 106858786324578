export const TAG_ALL = "all";
export const TAG_UNPROCESSED = "unprocessed";
export const TAG_PROCESSED = "processed";
export const TAG_PROBLEM = 20946400;
export const TAG_ARRANGE = 20982995;
export const TAG_PACKAGE = 20982998;
export const TAG_USER_PARENT_TAG = 20999296;

export const TAGS = {
  TAG_ALL,
  TAG_UNPROCESSED,
  TAG_PROCESSED,
  TAG_PROBLEM,
  TAG_ARRANGE,
  TAG_PACKAGE,
  TAG_USER_PARENT_TAG
};

export const PRODUCT_ID_DELIVERY_MORNING = 14645974;
export const PRODUCT_ID_DELIVERY_AFTERNOON = 14645981;
export const PRODUCT_ID_DELIVERY_EVENING = 14646028;

export const PRODUCT_IDS_DELIVERY = [
  PRODUCT_ID_DELIVERY_EVENING,
  PRODUCT_ID_DELIVERY_AFTERNOON,
  PRODUCT_ID_DELIVERY_MORNING
];

export const PRODUCT_ID_CARD = 3481857;
export const PRODUCT_ID_RIBBON = 17697042;

export const PRODUCT_ID_CARDS = [PRODUCT_ID_CARD, PRODUCT_ID_RIBBON];

export const IGNORE_PRODUCT_IDS = [
  943233,
  3481862, // payment
  3481856, // shipping NL
  17992480, // shipping NL ballonnensite
  3481862, // afterpay
  18007591 // discount
];

export const IGNORE_PROCESS_PRODUCT_IDS = IGNORE_PRODUCT_IDS.concat(PRODUCT_IDS_DELIVERY);

export const COUNTRY_CODES_BENELUX = ["BE", "NL", "LU"];

export default {
  TAGS,
  IGNORE_PRODUCT_IDS,
  IGNORE_PROCESS_PRODUCT_IDS,
  PRODUCT_ID_CARDS,
  COUNTRY_CODES_BENELUX
};
