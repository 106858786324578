import { createStore, applyMiddleware, compose, Store, AnyAction } from "redux";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory, History } from "history";
import { routerMiddleware } from "connected-react-router";

import rootReducer from "./reducers";
import promiseMiddleware from "./middleware/promise";
import routerEnhancer from "./middleware/routerEnhancer";

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();

window.sessionStorage.removeItem("location");

// ======================================================
// Store Enhancers
// ======================================================
const enhancers: any[] = [];
if (process.env.NODE_ENV === "development") {
  // @ts-ignore
  if (typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "function") {
    // @ts-ignore
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }
}

let store: Store<{}, AnyAction> &
  // @ts-ignore
  Store<S & {}, A> & {
    asyncReducers?: any;
    reduxFormReducerPlugins?: any;
    history: History;
  };

export const reduxFormReducerPlugins = {};

export const initStore = (initialState = {}) => {
  // @ts-ignore
  store = createStore(
    rootReducer(history),
    initialState,
    compose(
      applyMiddleware(promiseMiddleware, thunkMiddleware, routerEnhancer, routerMiddleware(history)),
      ...enhancers
    )
  );
  store.asyncReducers = {};
  store.reduxFormReducerPlugins = reduxFormReducerPlugins;

  store.history = history;

  // @ts-ignore
  if (process.env.NODE_ENV !== "production" && module.hot) {
    // @ts-ignore
    module.hot.accept("./reducers", () => {
      // @ts-ignore
      window.parent.store.replaceReducer(
        rootReducer(store?.history, store?.asyncReducers, store?.reduxFormReducerPlugins)
      );
    });
  }
  return store;
};

export const getStore = () => store;

// @ts-ignore
window.getStore = getStore;

export default initStore;
