import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-hot-loader";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

import Bootstrap from "./Bootstrap";
import ErrorBoundary from "./component/ErrorBoundary";
import { initStore } from "./store";

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line no-console
  console.log(process.env);
}

if (process.env.NODE_ENV === "production" && process.env.REACT_APP_SENTRY_PUBLIC_DSN) {
  const cfg = {
    dsn: process.env.REACT_APP_SENTRY_PUBLIC_DSN,
    environment: process.env.NODE_ENV.toUpperCase(),
    release: (process.env.REACT_APP_WERCKER_GIT_COMMIT || "CI=0").substring(0, 12),
    // An array of strings or regexps that'll be used to ignore specific errors based on their type/message
    ignoreErrors: [
      /PickleRick_\d\d/,
      "RangeError",
      "Unsuccessful HTTP response",
      /Auth token (expired|missing)/,
      "Request failed with status code 401",
      /^Request has been terminated/,
      "false"
    ]
  };
  console.log("Sentry: Initializing", cfg);
  Sentry.init(cfg);
}

const store = initStore();

const MOUNT_NODE = window.document.getElementById("root");
MOUNT_NODE?.setAttribute("notranslate", "1");

const render = (cmp: React.ReactElement) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={store.history}>
        <ErrorBoundary>{cmp}</ErrorBoundary>
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  );
};

render(<Bootstrap />);

// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept("./Bootstrap", () => {
    render(<Bootstrap />);
  });
}

// registerServiceWorker();
