import Immutable from "immutable";

import api from "../../inc/api";

// ------------------------------------
// Actions
// ------------------------------------

export const LOGIN = "@app/auth/LOGIN";
export const LOGOUT = "@app/auth/LOGOUT";
export const LOGIN_USER = "@app/auth/LOGIN_USER";
export const LOGOUT_USER = "@app/auth/LOGOUT_USER";
export const SET_ACTIVE_ROLE = "@app/auth/SET_ACTIVE_ROLE";
export const HYDRATE_PROFILE = "@app/auth/HYDRATE_PROFILE";
export const UPDATE_PROFILE = "@app/auth/UPDATE_PROFILE";
export const REFRESH_TOKEN = "@app/auth/REFRESH_TOKEN";
export const UPDATE_TOKEN = "@app/auth/UPDATE_TOKEN";
export const UPDATE_TOKENS = "@app/auth/UPDATE_TOKENS";
export const CLEAR_TOKENS = "@app/auth/CLEAR_TOKENS";
export const ADD_USER_CONTEXT = "@app/auth/ADD_USER";
export const REMOVE_USER_CONTEXT = "@app/auth/REMOVE_USER";

// ------------------------------------
// Action generators
// ------------------------------------

export const updateTokens = () => ({
  type: UPDATE_TOKENS,
  payload: JSON.parse(window.localStorage.getItem("authState")) || {}
});

export const setUserActiveRole = role => ({
  type: SET_ACTIVE_ROLE,
  payload: role
});

export const login = data => {
  if (data.id) {
    return {
      type: LOGIN_USER,
      payload: api.post("/workbench/auth/anonLogin", data)
    };
  }
  return {
    type: LOGIN,
    payload: api.post("/workbench/auth/login", data).type("form")
  };
};

export const logout = () => ({
  type: LOGOUT,
  payload: api.post("/workbench/auth/logout")
});

export const ping = () => ({
  type: "@app/auth/PING",
  payload: api.get("/workbench/auth/ping")
});

export const hydrateProfile = () => ({
  type: HYDRATE_PROFILE,
  payload: api.get("/workbench/auth/profile")
});

export const updateProfile = data => ({
  type: UPDATE_PROFILE,
  payload: api.post("/workbench/auth/profile", data)
});

window.localStorage.removeItem("authState2");
window.sessionStorage.removeItem("authState2");

export const clearTokens = () => {
  localStorage.removeItem("authState");
  sessionStorage.removeItem("authState");
  localStorage.removeItem("authState2");
  sessionStorage.removeItem("authState2");
  localStorage.removeItem("activeRole");
  sessionStorage.removeItem("activeRole");
  return {
    type: CLEAR_TOKENS
  };
};

export const addUserContext = ctxId => ({
  type: ADD_USER_CONTEXT,
  payload: ctxId
});

export const removeUserContext = (ctxId, force) => ({
  type: REMOVE_USER_CONTEXT,
  payload: { ctxId, force }
});

// ------------------------------------
// Default State
// ------------------------------------

const getCtxId = () => {
  if (window.name) {
    return window.name.replace("ctx", "");
  }
  // return 'master';
  return "user";
};

const State = new Immutable.Record({
  ctxId: getCtxId(),
  isLoggedOut: true,
  isLoggingOut: false,
  inProgress: false,
  error: null,
  tokens: Immutable.fromJS(JSON.parse(window.localStorage.getItem("authState")) || {}),
  profileInProgress: false,
  profileError: null,
  profileUpdateInProgress: false,
  profileUpdateError: null,
  profile: Immutable.Map(),
  activeRole: window.sessionStorage.getItem("activeRole") || null
});

// ------------------------------------
// Action Handlers
// ------------------------------------

const ACTION_HANDLERS = {
  [SET_ACTIVE_ROLE]: (state, { payload }) => state.setIn(["activeRole"], payload),
  [ADD_USER_CONTEXT]: (state, { payload }) => state.setIn(["tokens", payload], Immutable.Map()),
  [REMOVE_USER_CONTEXT]: (state, { payload }) => {
    if (payload.force) {
      return state.deleteIn(["tokens", payload.ctxId]).set("activeRole", null);
    }
    return state.deleteIn(["tokens", payload.ctxId, "token"]).set("activeRole", null);
  },
  [CLEAR_TOKENS]: (state, { payload }) => state.set("tokens", Immutable.Map()),
  [LOGIN]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("inProgress", true);
      case "success":
        return state
          .set("inProgress", false)
          .set("error", null)
          .delete("tokens")
          .setIn(["tokens", "master"], Immutable.fromJS(payload))
          .setIn(["tokens", "user"], Immutable.Map());
      case "error":
        return state.set("inProgress", false).set("error", payload);
      default:
        return state;
    }
  },
  [LOGIN_USER]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("inProgress", true);
      case "success":
        return state
          .set("inProgress", false)
          .set("error", null)
          .setIn(["tokens", state.get("ctxId")], Immutable.fromJS(payload));
      case "error":
        return state.set("inProgress", false).set("error", payload);
      default:
        return state;
    }
  },
  [LOGOUT]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("isLoggingOut", false);
      case "success":
        return state
          .set("isLoggingOut", false)
          .set("isLoggedOut", true)
          .set("error", null)
          .set("tokens", Immutable.Map());
      case "error":
        return state.set("isLoggingOut", false).set("error", payload);
      default:
        return state;
    }
  },
  [LOGOUT_USER]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("inProgress", true);
      case "success":
        return state
          .set("inProgress", false)
          .set("error", null)
          .deleteIn(["tokens", state.get("ctxId"), "token"]);
      case "error":
        return state.set("inProgress", false).set("error", payload);
      default:
        return state;
    }
  },
  [HYDRATE_PROFILE]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("profileInProgress", true);
      case "success":
        return state
          .set("profileInProgress", false)
          .set("profileError", null)
          .set("profile", Immutable.fromJS(payload));
      case "error":
        return state.set("profileInProgress", false).set("profileError", payload);
      default:
        return state;
    }
  },
  [UPDATE_PROFILE]: (state, { status, payload }) => {
    switch (status) {
      case "pending":
        return state.set("profileUpdateInProgress", true);
      case "success":
        return state
          .set("profileUpdateInProgress", false)
          .set("profileUpdateError", null)
          .set("profile", Immutable.fromJS(payload));
      case "error":
        return state.set("profileUpdateInProgress", false).set("profileError", payload);
      default:
        return state;
    }
  },
  [UPDATE_TOKENS]: (state, { payload }) => state.set("tokens", Immutable.fromJS(payload)),
  [UPDATE_TOKEN]: (state, { ctxId, payload }) => state.setIn(["tokens", ctxId], Immutable.fromJS(payload)),
  [REFRESH_TOKEN]: (state, { status, ctxId, payload }) => {
    switch (status) {
      case "pending":
        return state.set("inProgress", true);
      case "success":
        return state
          .set("inProgress", false)
          .set("error", null)
          .setIn(["tokens", ctxId], Immutable.fromJS(payload));
      case "error":
        return state.set("inProgress", false).set("error", payload);
      default:
        return state;
    }
  }
};

const persistState = state => {
  const authState = state.get("tokens");
  if (authState) {
    window.localStorage.setItem("authState", JSON.stringify(authState.toJS()));
  }

  if (state.get("activeRole")) {
    window.sessionStorage.setItem("activeRole", state.get("activeRole"));
  }
  return state;
};

// ------------------------------------
// Reducer
// ------------------------------------

export default function reducer(state = new State(), action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? persistState(handler(state, action)) : state;
}
